import React from "react";

const FileCard = ({ name, className, imageUrl, fileUrl }) => {
  const handleGetFile = () => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = name; // Optional: Provide a default filename for the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className={`card card-compact shadow-xl ${className}`}>
        <figure>
          <img src={imageUrl} alt="pfd" />
        </figure>
        <div className="card-body">
          <h2 className="card-title font-art text-amber-700 text-sm md:text-base">
            {name}
          </h2>

          <div className="card-actions justify-end">
            <button
              className="btn btn-primary bg-orange-700 text-white font-main hover:bg-white hover:text-amber-700 text-sm md:text-base"
              onClick={handleGetFile}
            >
              Λήψη
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileCard;
