import React from "react";

const FadeInImage = ({
  image,
  title = "",
  opacity = 1,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  onClick = () => {},
  ...props
}) => {
  const style = {
    transition: "opacity 0.5s ease",
    opacity,
  };

  return (
    <div
      id="FadeInImage"
      className="flex flex-col justify-center items-center gap-3"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      style={style}
    >
      <div
        id="FadeInImage-image"
        className="w-8/12 md:w-full rounded-xl overflow-hidden"
      >
        <img src={image} alt={title} className="w-full" />
      </div>
      {title && <p className="font-art text-xl text-black">{title}</p>}
    </div>
  );
};

export default FadeInImage;
