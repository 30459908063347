const backstageImages = [
  { name: "BackStagePhotos_1", url: "./images/bck/Sali_01.jpeg" },
  { name: "BackStagePhotos_2", url: "./images/bck/Sali_02.jpeg" },
  { name: "BackStagePhotos_3", url: "./images/bck/Sali_03.jpeg" },
  { name: "BackStagePhotos_4", url: "./images/bck/Sali_04.jpeg" },
  { name: "BackStagePhotos_5", url: "./images/bck/Sali_05.jpeg" },
  { name: "BackStagePhotos_6", url: "./images/bck/Sali_06.jpeg" },
  { name: "BackStagePhotos_7", url: "./images/bck/Sali_07.jpeg" },
  { name: "BackStagePhotos_8", url: "./images/bck/Sali_08.jpeg" },
  { name: "BackStagePhotos_9", url: "./images/bck/Sali_09.jpeg" },
  { name: "BackStagePhotos_10", url: "./images/bck/Sali_10.jpeg" },
  { name: "BackStagePhotos_11", url: "./images/bck/Sali_11.jpeg" },
  { name: "BackStagePhotos_12", url: "./images/bck/Sali_12.jpeg" },
  { name: "BackStagePhotos_13", url: "./images/bck/Sali_13.jpeg" },
  { name: "BackStagePhotos_14", url: "./images/bck/Sali_14.jpeg" },
  { name: "BackStagePhotos_15", url: "./images/bck/Sali_15.jpeg" },
  { name: "BackStagePhotos_16", url: "./images/bck/Sali_16.jpeg" },
  { name: "BackStagePhotos_17", url: "./images/bck/Sali_17.jpeg" },
  { name: "BackStagePhotos_18", url: "./images/bck/Sali_18.jpeg" },
  { name: "BackStagePhotos_19", url: "./images/bck/Sali_19.jpeg" },
  { name: "BackStagePhotos_20", url: "./images/bck/Sali_20.jpeg" },
  { name: "BackStagePhotos_21", url: "./images/bck/Sali_21.jpeg" },
  { name: "BackStagePhotos_22", url: "./images/bck/Sali_22.jpeg" },
  { name: "BackStagePhotos_23", url: "./images/bck/Sali_23.jpeg" },
  { name: "BackStagePhotos_24", url: "./images/bck/Sali_24.jpeg" },
  { name: "BackStagePhotos_25", url: "./images/bck/Sali_25.jpeg" },
  { name: "BackStagePhotos_26", url: "./images/bck/Sali_26.jpeg" },
  { name: "BackStagePhotos_27", url: "./images/bck/Sali_27.jpeg" },
  { name: "BackStagePhotos_28", url: "./images/bck/Sali_28.jpeg" },
  { name: "BackStagePhotos_29", url: "./images/bck/Sali_29.jpeg" },
  { name: "BackStagePhotos_30", url: "./images/bck/Sali_30.jpeg" },
  { name: "BackStagePhotos_31", url: "./images/bck/Sali_31.jpeg" },
  { name: "BackStagePhotos_32", url: "./images/bck/Sali_32.jpeg" },
  { name: "BackStagePhotos_33", url: "./images/bck/Sali_33.jpeg" },
  { name: "BackStagePhotos_34", url: "./images/bck/Sali_34.jpeg" },
  { name: "BackStagePhotos_35", url: "./images/bck/Sali_35.jpeg" },
  { name: "BackStagePhotos_36", url: "./images/bck/Sali_36.jpeg" },
  { name: "BackStagePhotos_37", url: "./images/bck/Sali_37.jpeg" },
  { name: "BackStagePhotos_38", url: "./images/bck/Sali_38.jpeg" },
  { name: "BackStagePhotos_39", url: "./images/bck/Sali_39.jpeg" },
  { name: "BackStagePhotos_40", url: "./images/bck/Sali_40.jpeg" },
  { name: "BackStagePhotos_41", url: "./images/bck/Sali_41.jpeg" },
  { name: "BackStagePhotos_42", url: "./images/bck/Sali_42.jpeg" },
  { name: "BackStagePhotos_43", url: "./images/bck/Sali_43.jpeg" },
  { name: "BackStagePhotos_44", url: "./images/bck/Sali_44.jpeg" },
  { name: "BackStagePhotos_45", url: "./images/bck/Sali_45.jpeg" },
  { name: "BackStagePhotos_46", url: "./images/bck/Sali_46.jpeg" },
  { name: "BackStagePhotos_47", url: "./images/bck/Sali_47.jpeg" },
  { name: "BackStagePhotos_48", url: "./images/bck/Sali_48.jpeg" },
  { name: "BackStagePhotos_49", url: "./images/bck/Sali_49.jpeg" },
  { name: "BackStagePhotos_50", url: "./images/bck/Sali_50.jpeg" },
  { name: "BackStagePhotos_51", url: "./images/bck/Sali_51.jpeg" },
];

export { backstageImages };
