import React from "react";

const ButtonCard = ({
  text,
  title,
  className,
  goToSection,
  onClick = () => {},
}) => {
  return (
    <div
      className={`card shadow-xl ${className}`}
      onClick={() => onClick(parseInt(goToSection))}
    >
      <div className="card-body">
        <h2 className="font-art text-md md:text-base text-amber-700 text-center">
          {title}
        </h2>
        <p className="font-main text-gray-800 text-sm md:text-xs text text-center">
          {text}
        </p>
      </div>
    </div>
  );
};

export default ButtonCard;
