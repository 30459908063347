import React, { useState, useRef, useEffect } from "react";
import YouTubeVideo from "./YouTubePlayer";
import { IoCloseCircleSharp } from "react-icons/io5";

const VideoPlayer = ({
  shouldAutoPlay,
  videoID,
  onEndVideo = () => {},
  sheneID,
}) => {
  const [videoReady, setVideoReady] = useState(false);
  const [showYoutube, setShowYoutube] = useState(true);
  const playRef = useRef(null);
  const stopRef = useRef(null);

  //#region Youtube

  const handleVideoReady = () => {
    setVideoReady(true);
  };

  // Auto-play video when both video is ready and autoplay is indicated
  useEffect(() => {
    if (videoReady && shouldAutoPlay && playRef.current) {
      playRef.current();
    }
  }, [videoReady, shouldAutoPlay]);

  const handleVideoPlay = () => {
    console.log("Video started playing");
  };

  const handleVideoStop = () => {
    console.log("Video stopped");
    setShowYoutube(false);
    onEndVideo(parseInt(sheneID) + 1);
  };

  //#endregion

  return (
    <div
      id="youtubecontainer"
      className="absolute w-10/12 aspect-video top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
    >
      {showYoutube && (
        <>
          <YouTubeVideo
            className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full flex fle-col justify-center items-center"
            onPlay={handleVideoPlay}
            onStop={handleVideoStop}
            play={playRef}
            stop={stopRef}
            videoId={videoID}
            width={(window.innerWidth * 10) / 12}
            onReady={handleVideoReady}
          />

          <IoCloseCircleSharp
            id="youtube-close-button"
            className="absolute  -top-10 -right-10 text-amber-700 text-4xl z-20"
            onClick={() => {
              stopRef.current();
              handleVideoStop();
            }}
          />
        </>
      )}
    </div>
  );
};

export default VideoPlayer;
