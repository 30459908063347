import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";

const FadeTransition = ({
  children,
  fadeIn = true,
  fadeOut = false,
  delay = 0,
  duration = 0.5,
  onEnd = () => {},
  className = "flex-col gap-3",
}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const timeline = gsap.timeline({ onComplete: onEnd });

      if (fadeIn) {
        gsap.set(containerRef.current, { opacity: 0 });
        timeline.to(containerRef.current, {
          opacity: 1,
          duration: duration,
        });
      }

      if (fadeOut) {
        timeline.to(
          containerRef.current,
          {
            opacity: 0,
            duration: duration,
            delay: fadeIn ? delay : 0,
          },
          fadeIn ? "+=0" : "0"
        );
      }

      // Clean up the animation on component unmount
      return () => {
        timeline.kill();
      };
    }
  }, [fadeIn, fadeOut, delay, duration, onEnd]);

  return (
    <div
      ref={containerRef}
      className={`w-full h-full flex justify-center items-center ${className}`}
    >
      {children}
    </div>
  );
};

export default FadeTransition;
