import React, { useState } from "react";

const MasonrySlider = ({ images, className }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (image) => {
    setSelectedImage(image);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className={`relative ${className} overflow-y-auto`}>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.url}
            alt={image.name}
            onClick={() => openImage(image)}
            className="w-full cursor-pointer mb-2 object-cover"
            style={{ height: "300px" }} // Adjust height as needed
          />
        ))}
      </div>

      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
          <div className="relative max-w-[80%] max-h-[80%] p-4">
            <img
              src={selectedImage.url}
              alt={selectedImage.name}
              className="w-full h-auto rounded-lg shadow-lg"
            />
            <button
              onClick={closeImage}
              className="absolute top-0 right-0 mt-4 mr-4 p-2 bg-white border-none rounded-md cursor-pointer text-black"
            >
              Close
            </button>
            <p className="text-white text-center mt-2">{selectedImage.name}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MasonrySlider;
