import React, { useState, useRef, useEffect, useMemo } from "react";
import LogoAndImageSequence from "./Components/LogoAndImageSequence";
import Footer from "./Components/Footer";
import VideoPlayer from "./Components/VideoPlayer";
import LocalVideoPlayer from "./Components/LocalVideoPlayer";
import Header from "./Components/Header";
import MasonrySlider from "./Components/MasonrySlider";
import FileCard from "./Components/FileCard";
import FadeInImage from "./Components/FadeInImage";
import ButtonCard from "./Components/ButtonCard";
import ThankYouComponent from "./Components/ThankYouComponent.jsx";
import { backstageImages } from "./assets/backstageList.js";

const App = () => {
  const [scene, setScene] = useState(0);
  const [building, setBuilding] = useState(null);
  const [shouldAutoPlay, setShouldAutoPlay] = useState(false);
  const [stopVideos, setStopVideos] = useState(false);

  const soufliImages = [
    {
      name: "ΣΟΥΦΛΙΩΤΙΣΣΕΣ 1930 1940 ΜΠΡΟΣΤΑ ΣΕ ΒΑΓΟΝΙ",
      url: "./images/soufli/1.jpg",
    },
    { name: "1938 ΓΥΜΝΑΣΙΟ ΣΟΥΦΛΙΟΥ", url: "./images/soufli/2.jpg" },
    { name: "1938 ΓΥΜΝΑΣΙΟ ΣΟΥΦΛΙΟΥ", url: "./images/soufli/3.jpg" },
    { name: "1937 ΕΚΔΡΟΜΗ ΜΑΘΗΤΩΝ ΓΥΜΝΑΣΙΟΥ", url: "./images/soufli/3.jpg" },
    { name: "1937 ΕΚΔΡΟΜΗ ΜΑΘΗΤΩΝ ΓΥΜΝΑΣΙΟΥ", url: "./images/soufli/4.jpg" },
    { name: "1924 Σ.Σ", url: "./images/soufli/5.jpg" },
    { name: "1920-1930 ΤΟ ΓΥΜΝΑΣΙΟ", url: "./images/soufli/6.jpg" },
    { name: "1920-1930 ΤΟ ΓΥΜΝΑΣΙΟ", url: "./images/soufli/7.jpg" },
  ];

  const handleBuildingSelect = (name) => {
    setBuilding(name);
    setShouldAutoPlay(true);
    setScene(4);
  };

  const handleSceneChange = (number) => {
    setScene(number);
  };

  const hanldeStopAllVideo = () => {
    setStopVideos(!stopVideos);
  };

  return (
    <div id="App" className="w-screen h-screen bg-white overflow-hidden">
      {parseInt(scene) <= 3 && (
        <LogoAndImageSequence
          onScheneChange={(sceneNumber) => {
            setScene(sceneNumber);
          }}
          onClick={handleBuildingSelect}
        />
      )}

      {parseInt(scene) >= 3 && <Footer />}

      {parseInt(scene) >= 3 && (
        <Header
          goToScene={handleSceneChange}
          stopAllVideos={hanldeStopAllVideo}
        />
      )}
      {building !== null && scene === 4 && (
        <VideoPlayer
          videoID={building === "Sali" ? "OQtiSPZzhHc" : "SHcN2Z5NUYY"}
          sheneID={building === "Sali" ? "4" : "9"}
          shouldAutoPlay={shouldAutoPlay}
          onEndVideo={(sceneId) => {
            setScene(sceneId);
          }}
        />
      )}

      {scene === 5 && (
        <div className="mt-[120px] h-[calc(100vh-180px)]  overflow-hidden flex flex-col  md:flex-col justify-start  md:justify-center items-center gap-5 overflow-y-auto">
          <div className="w-8/12 md:w-5/12 ">
            <FadeInImage image="./images/sali-small.jpg" />
          </div>

          <div className="w-8/12 flex flex-col md:flex-row justify-center items-center  gap-2 md:gap-5">
            <ButtonCard
              title="Το βίντεο"
              text="Η αναπαράσταση των χρόνων στο κτήριο."
              className="h-[100px] w-full md:w-[22%]"
              goToSection="6"
              onClick={handleSceneChange}
            />
            <ButtonCard
              title="Η ιστορία του κτιρίου"
              text="Αφήγηση της ιστορίας του Sali Boutique Hotel"
              className="h-[100px] w-full md:w-[22%]"
              goToSection="7"
              onClick={handleSceneChange}
            />
            <ButtonCard
              title="Μαθητικές εργασίες"
              text="Διαβάστε την έρευνα που πραγματοποίησαν οι μαθητές"
              className="h-[100px] w-full md:w-[22%]"
              goToSection="8"
              onClick={handleSceneChange}
            />
            <ButtonCard
              title="BackStage"
              text="Φωτογραφίες απο τα γυρίσματα"
              className="h-[100px] w-full md:w-[22%]"
              goToSection="9"
              onClick={handleSceneChange}
            />
          </div>
        </div>
      )}
      {/* Sali Video */}
      {scene === 6 && (
        <div>
          <VideoPlayer
            videoID="OQtiSPZzhHc"
            sheneID="10"
            shouldAutoPlay={true}
            onEndVideo={(sceneId) => {
              setScene(10);
            }}
          />
        </div>
      )}
      {/* Sali Avatar */}
      {scene === 7 && (
        <div className="w-full h-screen overflow-hidden flex flex-col justify-center items-center">
          <p className="font-art text-xl font-semibold text-amber-700 mb-5">
            Η ιστορία του Sali Boutique Hotel
          </p>
          <div className="h-[70%] aspect-[9/16] overflow-hidden rounded-2xl">
            <LocalVideoPlayer
              url="./videos/Sali.mp4"
              play={true}
              stop={stopVideos}
              onEnd={() => {}}
              className="h-full w-full"
            />
          </div>
        </div>
      )}

      {/* Sali Εργασιες */}
      {scene === 8 && (
        <div className="mt-[120px] h-[calc(100vh-180px)]  overflow-hidden flex flex-col md:flex-row justify-start  md:justify-center items-center gap-5 overflow-y-auto">
          <FileCard
            imageUrl="./images/ergasies/euplouu.jpg"
            name="Άγιος Εύπλου Αλεξανδρούπολης"
            fileUrl="./files/Agios-Euplous.pdf"
            className="w-8/12 md:w-3/12"
          />
          <FileCard
            imageUrl="./images/ergasies/kapnomagazo.jpg"
            name="Καπνομάγαζο Αλεξανδρούπολης"
            fileUrl="./files/Kapnomagazo.pdf"
            className="w-8/12 md:w-3/12"
          />
        </div>
      )}

      {/* Sali BackStage */}
      {scene === 9 && (
        <div className="mt-[120px] h-[calc(100vh-180px)]  overflow-hidden flex flex-col justify-start  md:justify-center items-center gap-5 overflow-y-auto">
          <p className="font-art text-sm text-amber-700 px-[3%] text-center">
            Καλωσορίστε στον μαγικό κόσμο πίσω από τις σκηνές: όπου η φαντασία
            γίνεται πραγματικότητα!
          </p>
          <MasonrySlider
            images={backstageImages}
            className="h-[calc(100vh-180px)] flex justify-start items-start w-[80%] mx-auto"
          />
        </div>
      )}

      {/* ligrary */}
      {scene === 10 && (
        <div className="mt-[120px] h-[calc(100vh-180px)]  overflow-hidden flex flex-col  md:flex-col justify-start  md:justify-center items-center gap-5 overflow-y-auto">
          <div className="w-8/12 md:w-5/12 ">
            <FadeInImage image="./images/soufli-small.jpg" />
          </div>

          <div className="w-8/12 flex flex-col md:flex-row justify-center items-center  gap-2 md:gap-5">
            <ButtonCard
              title="Το βίντεο"
              text="Η αναπαράσταση των χρόνων στο κτήριο."
              className="h-[100px] w-full md:w-[22%]"
              goToSection="11"
              onClick={handleSceneChange}
            />
            <ButtonCard
              title="Η ιστορία του κτιρίου"
              text="Αφήγηση της ιστορίας του Sali Boutique Hotel"
              className="h-[100px] w-full md:w-[22%]"
              goToSection="12"
              onClick={handleSceneChange}
            />
            <ButtonCard
              title="Φωτογραφίες του κτιρίου"
              text="Ιστορικές Φωτογραφίες του γυμνασίου"
              className="h-[100px] w-full md:w-[22%]"
              goToSection="13"
              onClick={handleSceneChange}
            />
            <ButtonCard
              title="Μαθητικές εργασίες"
              text="Διαβάστε την έρευνα που πραγματοποίησαν οι μαθητές"
              className="h-[100px] w-full md:w-[22%]"
              goToSection="14"
              onClick={handleSceneChange}
            />
            <ButtonCard
              title="Η Μαθητική Ομάδα"
              text="Τα άτομα που συμμετείχανε στην έρευνα"
              className="h-[100px] w-full md:w-[22%]"
              goToSection="15"
              onClick={handleSceneChange}
            />
          </div>
        </div>
      )}

      {scene === 11 && (
        <div className="w-full h-screen overflow-hidden">
          <VideoPlayer
            videoID="SHcN2Z5NUYY"
            sheneID="10"
            shouldAutoPlay={true}
            onEndVideo={(sceneId) => {
              setScene(10);
            }}
          />
        </div>
      )}
      {scene === 12 && (
        <div className="w-full h-screen overflow-hidden flex flex-col justify-center items-center">
          <p className="font-art text-xl font-semibold text-amber-800 mb-5 ">
            Η ιστορία της Δημοτικής Βιβλιοθήκης Σουφλίου
          </p>
          <div className="h-[70%] aspect-[9/16] overflow-hidden rounded-2xl">
            <LocalVideoPlayer
              url="./videos/Soufli.mp4"
              play={true}
              stop={stopVideos}
              onEnd={() => {}}
              className="h-full w-full"
            />
          </div>
        </div>
      )}
      {scene === 13 && (
        <div className="w-full h-screen overflow-hidden">
          <MasonrySlider
            images={soufliImages}
            className="mt-[120px] h-[calc(100vh-180px)] flex justify-start items-start w-[80%] mx-auto"
          />
        </div>
      )}
      {scene === 14 && (
        <div className="mt-[120px] h-[calc(100vh-180px)]  overflow-hidden flex flex-row justify-center items-center">
          <FileCard
            imageUrl="./images/ergasies/soufli.jpg"
            name="Εργασία Ομάδας Σουφλίου"
            fileUrl="./files/Soufli.pdf"
            className="w-8/12 md:w-3/12"
          />
        </div>
      )}
      {scene === 15 && (
        <div className="mt-[120px] h-[calc(100vh-180px)] overflow-hidden flex flex-row justify-start items-center">
          <ThankYouComponent className="w-full h-[calc(100vh-180px)] overflow-y-auto" />
        </div>
      )}
    </div>
  );
};

export default App;
