import React from "react";
import FadeTransition from "./FadeTransition";

const Footer = () => {
  return (
    <div className=" absolute bottom-0 left-0 h-[50px] w-screen bg-white border-t-1 flex flex-row justify-between items-center px-4 md:px-[20%] ">
      <p
        className="text-main text-xs md:text-base scale-60 md:scale-100 font-main text-amber-800"
        onClick={() => {
          window.location.href = "https://thrakisgennimata.gr/";
        }}
      >
        @Θράκης Γεννήματα 2023
      </p>
      <img src="./images/ypogreio.jpg" className="h-full"></img>
      <p
        className="text-main text-xs md:text-base scale-60 md:scale-100 font-main text-amber-800"
        onClick={() => {
          window.location.href = "https://slaps.gr/";
        }}
      >
        created by:Slaps4Brands
      </p>
    </div>
  );
};

export default Footer;
