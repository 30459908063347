import React from "react";

function ThankYouComponent({ className }) {
  return (
    <div className={className}>
      <div className="flex flex-col justify-start items-center">
        <p className="font-art font-normal text-xl my-2 text-amber-700">
          Ευχαριστούμε πολύ:
        </p>
        <p className="text-sm md:text-base text-black font-main">
          το Στρατηγό Διοικητή 12ης Μεραρχίας Αλεξανδρούπολης κ. Μπακιρτζή
          Χρήστο
        </p>
        <p className="text-sm md:text-base text-black font-main">
          το Στρατηγό Διοικητή 16ης Μεραρχίας Διδυμοτείχου κ. Καραμανίδη
          Κωνσταντίνο
        </p>
        <p className="text-sm md:text-base text-black font-main">
          το Στρατιωτικό μουσείο Διδυμοτείχου
        </p>
        <p className="text-sm md:text-base text-black font-main">
          το Μουσείο Τέχνης Μεταξιού Σουφλίου ,την κ. Σούλα και Γιώργο Τσακίρη
        </p>
        <p className="text-sm md:text-base text-black font-main">
          το Μουσείο λαογραφίας και φυσικής ιστορίας π.Γεώργιου Κομνίδη στην
          Ν.Χιλή
        </p>
        <p className="text-sm md:text-base text-black font-main">
          το κατάστημα ενδυμάτων Σουμάκα Ιωάννη στο Σουφλί
        </p>
        <p className="text-sm md:text-base text-black font-main">
          το ΚΑΠΗ Σουφλίου
        </p>
        <p className="text-sm md:text-base text-black font-main">
          την Εκκλησιαστική Μαντολινάτα Σουφλίου
        </p>
        <p className="text-sm md:text-base text-black font-main">
          την κ. Αθανασία Καρακατσάνη μουσικό και επιμελητή της δράσης.
        </p>

        <p className="font-art font-normal text-xl my-2 text-amber-700">
          Ομάδα Εργασίας:
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Ζηκίδη Μαρία
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Δημισκίδη Ααποστολία
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Καραγιάννη Μαρία
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Ιωαννίδη Ανθή
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Μέρμηγκα Αναστασία
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Μπλίτσας Φοίβος
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Σακκιάδης Στυλιανός
        </p>

        <p className="font-art font-normal text-xl my-2 text-amber-700">
          Μαθητές Λυκείου Σουφλίου:
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Πασχάλη Χρύσα
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Αλατζιά Χρυσούλα
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Κρουστάλλη Μαρία
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Παπάζογλου Ιωάννης
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Δημητριάδη Φωτεινή
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Καλόγερος Δημήτρης
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Ιωάννου Παναγιώτης
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Καψάλη Παρασκευή
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Κοντογούνη Νεκταρία
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Γκουντινάκη Χριστίνα
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Κεχαγιά Ντιλιά
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Παρασκευά Αγάπη
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Αλεξούδη Γεωργία
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Δαλαβέρα Χριστίνα
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Γκούντλη Χριστίνα
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Μπεκίρ Ουστά Ασλί
        </p>

        <p className="font-art font-normal text-xl my-2 text-amber-700">
          Καθηγήτριες:
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Μαρία Πατσιά και Λεμονιά Γκάγκου
        </p>

        <p className="font-art font-normal text-xl my-2 text-amber-700">
          Επιμέλεια κειμένων
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Παναγιώτα Γκαγκούλια
        </p>

        <p className="font-art font-normal text-xl my-2 text-amber-700">
          Σενάριο - Σκηνικά:
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Χρύσα Αργυρακάκη
        </p>

        <p className="font-art font-normal text-xl my-2 text-amber-700">
          Παραγωγή:
        </p>
        <p className="text-sm md:text-base text-black font-main">
          Κώστας Ορδουμποζάνης
        </p>
      </div>
    </div>
  );
}

export default ThankYouComponent;
