import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import FadeTransition from "./FadeTransition";
import FadeInImage from "./FadeInImage";
import Footer from "./Footer";

const LogoAndImageSequence = ({
  onScheneChange = () => {},
  onClick = () => {},
}) => {
  const [stage, setStage] = useState(0);
  const [hovered, setHovered] = useState(null);

  useEffect(() => {
    if (stage === 0) {
      setTimeout(() => setStage(1), 3500); // Logo 1 display time (3000 ms) + fade out duration (500 ms)
    } else if (stage === 1) {
      setTimeout(() => setStage(2), 3500); // Logo 2 display time (3000 ms) + fade out duration (500 ms)
    } else if (stage === 2) {
      setTimeout(() => setStage(3), 3500); // Logo 2 display time (3000 ms) + fade out duration (500 ms)
    }

    onScheneChange(stage);
  }, [stage]);

  return (
    <div
      id="intro"
      className="w-full h-full flex flex-col justify-center items-center"
    >
      {stage === 0 && (
        <FadeTransition fadeIn fadeOut delay={1.5}>
          <img
            src="./images/thrakis-logo.jpg"
            alt="Logo 1"
            className="w-[10%] "
          />
          <p className="font-art text-3xl text-black">Θράκης Γεννήματα</p>
        </FadeTransition>
      )}
      {stage === 1 && (
        <FadeTransition
          fadeIn
          fadeOut
          delay={1.5}
          className="flex-col justify-center items-center gap-3"
        >
          <img
            src="./images/ypogreio.jpg"
            alt="Logo 2"
            className="w-10/12 md:w-[30%]"
          />
          <img
            src="./images/monumenta_logo.png"
            alt="Logo 2"
            className="w-10/12 md:w-[20%]"
          />
        </FadeTransition>
      )}
      {stage === 2 && (
        <div>
          <FadeTransition fadeIn fadeOut delay={4}>
            <p className="font-art text-base md:text-3xl text-black">
              Τα κτίρια μας μιλούν και αναπνέουν...
            </p>
          </FadeTransition>
        </div>
      )}
      {stage === 3 && (
        <>
          {/* <img src="./images/thrakis-logo.jpg" alt="" className="w-16 mb-20" /> */}

          <div className="flex flex-col md:flex-row gap-5 h-[calc(100%-150px)] overflow-hidden mt-[60px] justify-center items-center">
            <FadeInImage
              image="./images/sali-small.jpg"
              title="Sali Boutique Hotel"
              opacity={hovered === "second" ? 0.5 : 1}
              onMouseEnter={() => setHovered("first")}
              onMouseLeave={() => setHovered(null)}
              onClick={() => {
                onClick("Sali");
              }}
            />
            <FadeInImage
              image="./images/soufli-small.jpg"
              title="Δημοτική Βιβλιοθήκη Σουφλίου"
              opacity={hovered === "first" ? 0.5 : 1}
              onMouseEnter={() => setHovered("second")}
              onMouseLeave={() => setHovered(null)}
              onClick={() => {
                onClick("Library");
              }}
            />
          </div>
          {!isMobile && (
            <p className="text-art text-gray-800 text-md mt-10">
              Επιλεξτε ένα κτήριο
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default LogoAndImageSequence;
