import React, { useState, useEffect } from "react";
import { ImMenu } from "react-icons/im";
import { isMobile } from "react-device-detect";

const Header = ({
  goToScene = () => {},
  newScene = false,
  stopAllVideos = () => {},
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    stopAllVideos();
    setShowMenu((prev) => !prev);
  };

  // Modified handleOnClick to return a function
  const handleOnClick = (number) => {
    return () => {
      goToScene(number);
      toggleMenu();
    };
  };

  useEffect(() => {
    newScene === true && setShowMenu(false);
  }, [newScene]);

  return (
    <>
      {/* logo */}
      <img
        src="./images/thrakis-logo.jpg"
        alt="logo"
        className="absolute top-[30px] md:top-[50px] left-[30px] md:left-[50px] w-8 md:w-[50px]"
        onClick={() => {
          goToScene(3);
        }}
      />
      {/* menuIcon */}
      <ImMenu
        onClick={toggleMenu}
        className={`absolute top-[30px] md:top-[50px] right-[30px] md:right-[50px] text-2xl ${
          showMenu ? "text-amber-700" : "text-black"
        } z-50`}
      />

      {/* menu bar */}
      {showMenu && (
        <div
          id="menu"
          className={`w-screen h-screen absolute top-0 right-0 flex flex-col gap-4 justify-center items-center bg-white z-40`}
        >
          <img
            src="./images/thrakis-logo.jpg"
            alt="logo"
            className="w-12"
            onClick={() => {
              window.location.href = "https://thrakisgennimata.gr/";
            }}
          />

          <div
            id="SaliMeniContainer"
            className="flex flex-col justify-start items-center gap-3"
          >
            <p
              className="font-art font-bold text-2xl text-amber-700"
              onClick={handleOnClick(5)}
            >
              Sali Boutique Hotel
            </p>
            <p
              className="text-base text-black font-main hover:font-bold hover:text-amber-700"
              onClick={handleOnClick(6)}
            >
              Το Video
            </p>
            <p
              className="text-base text-black font-main hover:font-bold hover:text-amber-700"
              onClick={handleOnClick(7)}
            >
              Η ιστορία του Sali
            </p>
            <p
              className="text-base text-black font-main hover:font-bold hover:text-amber-700"
              onClick={handleOnClick(8)}
            >
              Μαθητικές Εργασίες
            </p>
            <p
              className="text-base text-black font-main hover:font-bold hover:text-amber-700"
              onClick={handleOnClick(9)}
            >
              BackStage
            </p>
          </div>
          <div
            id="LibMenuContainer"
            className="flex flex-col justify-start items-center gap-3"
          >
            <p
              className="font-art font-bold text-2xl text-amber-700 mt-10"
              onClick={handleOnClick(10)}
            >
              Δημοτική Βιβλιοθήκη Σουφλίου
            </p>
            <p
              className="text-base text-black font-main hover:font-bold hover:text-amber-700"
              onClick={handleOnClick(11)}
            >
              Το Video
            </p>
            <p
              className="text-base text-black font-main hover:font-bold hover:text-amber-700"
              onClick={handleOnClick(12)}
            >
              Η ιστορία της Βιβλιοθήκης
            </p>
            <p
              className="text-base text-black font-main hover:font-bold hover:text-amber-700"
              onClick={handleOnClick(13)}
            >
              Φωτογραφίες Κτιρίου
            </p>
            <p
              className="text-base text-black font-main hover:font-bold hover:text-amber-700"
              onClick={handleOnClick(14)}
            >
              Μαθητικές Εργασίες
            </p>
            <p
              className="text-base text-black font-main hover:font-bold hover:text-amber-700"
              onClick={handleOnClick(15)}
            >
              Η Ομάδα
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
