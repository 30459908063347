import React, { useRef, useEffect } from "react";
import YouTube from "react-youtube";

const YouTubeVideo = ({
  className,
  onPlay,
  onStop,
  play,
  stop,
  width,
  videoId,
  onReady,
}) => {
  const youtubeRef = useRef(null);
  const containerRef = useRef(null);
  const isReadyRef = useRef(false);

  // Expose play and stop methods
  useEffect(() => {
    play.current = () => {
      if (isReadyRef.current) {
        onPlay();
        youtubeRef.current.internalPlayer.playVideo();
      }
    };
    stop.current = () => {
      if (isReadyRef.current) {
        onStop();
        youtubeRef.current.internalPlayer.stopVideo();
      }
    };
  }, [onPlay, onStop, play, stop]);

  const opts = {
    height: `${(parseInt(width) * 9) / 16}px`,
    width: `${width}px`,
    playerVars: {
      autoplay: 0,
    },
  };

  // Handle when YouTube player is ready
  const handlePlayerReady = (event) => {
    isReadyRef.current = true;
    onReady(event);
  };

  return (
    <div ref={containerRef} id="YoutubeContainer" className={className}>
      <YouTube
        ref={youtubeRef}
        videoId={videoId}
        opts={opts}
        onReady={handlePlayerReady}
        onPlay={() => onPlay()}
        onEnd={() => onStop()}
      />
    </div>
  );
};

export default YouTubeVideo;
