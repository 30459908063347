import React, { useEffect, useRef } from "react";

const LocalVideoPlayer = ({ url, play, stop, onEnd, className }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (play) {
      videoRef.current.play();
    }

    if (stop) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [play, stop]);

  const handleOnEnd = () => {
    onEnd();
    // Reset the video to the start
    videoRef.current.currentTime = 0;
  };

  return (
    <video
      ref={videoRef}
      src={url}
      className={className}
      onEnded={handleOnEnd}
      playsInline // important for iOS
      controls={false} // Assuming you don't want default controls
      disableRemotePlayback // This disables the casting icon
    />
  );
};

export default LocalVideoPlayer;
